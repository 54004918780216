import React, { Component } from 'react';

const introImage = {
    background: `url('img/home.jpg') center center no-repeat`,
    backgroundSize: 'cover'
  };

class Banner extends Component {
    render() {
        return (
            <section id="intro" style={introImage} className="intro-section pb-2">
                <div className="container text-center">
                    {/* <div data-animate="fadeInDown" className="logo"><img src="/img/logo-big.png" alt="logo" width="130" /></div> */}
                    <h1 data-animate="fadeInDown" className="text-shadow mb-5">You deserve the wise choice.</h1>
                    <p data-animate="slideInUp" className="h3 text-shadow text-400">We provide web solutions and mobile applications for you.</p>
                </div>
            </section>
        );
    }
}

export default Banner;