import React, { Component } from 'react';
// import App from './App';
// import UnderConstruct from './components/under-construct/UnderConstruct';
// import * as serviceWorker from './serviceWorker';
import Banner from '../website/Banner';
import AboutUs from '../website/AboutUs';
import Service from '../website/Service';
import Clients from '../website/Clients';
import Contact from '../website/Contact';

export default class Main extends Component {
    render(){
       return(
            <div>
               <Banner />
               <AboutUs />
               <Service />
               <Clients />
               <Contact />
            </div>
       )
    }
 }