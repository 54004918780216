import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/main.scss'
// import App from './App';
// import UnderConstruct from './components/under-construct/UnderConstruct';
// import * as serviceWorker from './serviceWorker';
import Header from './components/website/Header';
import Main from './components/website/Main';
import Footer from './components/website/Footer';


// Under Construction
// ReactDOM.render(<UnderConstruct />, document.getElementById('root'));

ReactDOM.render(<Header />, document.getElementById('nav'));
ReactDOM.render(<Main />, document.getElementById('root'));
ReactDOM.render(<Footer />, document.getElementById('footer'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
