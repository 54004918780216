import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                {/* <div className="col-md-6 text-center text-lg-left">
                    <p className="social">
                        <a href="https://www.facebook.com/" className="external facebook wow fadeInUp" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/" data-wow-delay="0.2s" className="external instagram wow fadeInUp" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                        <a href="mailto:puncorp.app@gmail.com" data-wow-delay="0.6s" className="email wow fadeInUp" rel="noopener noreferrer"><i className="fas fa-envelope"></i></a>
                    </p>
                </div> */}
                <div className="col-md-12 text-center mt-4 mt-lg-0">
                    <p>© 2013 - {new Date().getFullYear()} Pun Corporation. All rights reserved.</p>
                </div>
                {/* <div className="col-12 mt-4">
                    <p className="template-bootstrapious">Template by <a href="https://bootstrapious.com/p/bootstrap-carousel" target="_blank" rel="noopener noreferrer">Bootstrapious</a> & <a href="https://fity.cz/ostrava">Fity</a></p>
                </div> */}
                </div>
            </div>
        );
    }
}

export default Footer;