import React, { Component } from 'react';

class AboutUs extends Component {
    render() {
        const boldText = {fontWeight : 'bold'};
        const sizeText = {fontSize: '1.2rem', lineHeight: '2rem'};

        return (
            <section id="about" className="about-section" style={sizeText}>
                <div className="container">
                    <header className="text-center">
                    <h2 data-animate="fadeInDown" className="title">About Us</h2>
                    </header>
                    <div className="row">
                        <div data-animate="fadeInUp" className="col-lg-6">
                            <p>Welcome to <span style={boldText}>Pun Corporation</span>, your number one source for all things about web solutions and mobile applications. We're dedicated to giving you the very best of our solution, with a focus on reliability, usability, and user experience.</p>
                            <p>Founded in 2013, Pun Corporation has come a long way from its beginnings in Bangkok. When we first started out our passion for mobile application across multiple platforms drove them to do tons of work so that Pun Corporation can offer you the better choice.</p>
                            <p>We hope you enjoy our products as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us.</p>
                        </div>
                        {/* <div data-animate="fadeInUp" className="col-lg-6">
                            <div className="skill-item">
                                <div className="progress-title">PHP</div>
                                <div className="progress">
                                    <div role="progressbar" style="width: 60%" aria-valuenow="0" aria-valuemin="60" aria-valuemax="100" className="progress-bar progress-bar-skill1"></div>
                                </div>
                            </div>
                            <div className="skill-item">
                                <div className="progress-title">Javascript</div>
                                <div className="progress">
                                    <div role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="60" aria-valuemax="100" className="progress-bar progress-bar-skill2"></div>
                                </div>
                            </div>
                            <div className="skill-item">
                                <div className="progress-title">HTML coding</div>
                                <div className="progress">
                                    <div role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" className="progress-bar progress-bar-skill3"></div>
                                </div>
                            </div>
                            <div className="skill-item">
                                <div className="progress-title">SEO</div>
                                <div className="progress">
                                    <div role="progressbar" style="width: 90%" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" className="progress-bar progress-bar-skill4"></div>
                                </div>
                            </div>
                            <div className="skill-item">
                                <div className="progress-title">SEM</div>
                                <div className="progress">
                                    <div role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" className="progress-bar progress-bar-skill5"></div>
                                </div>
                            </div>
                        </div> */}
                        <div data-animate="fadeInUp" className="col-lg-6 mx-auto mt-2 text-center">
                            <img src="img/logo.png" alt="Pun Corporation" className="image rounded-circle img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutUs;