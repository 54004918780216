import React, { Component } from 'react';
// import ContactForm from './ContactForm';


class Contact extends Component {

    render() {

        const sizeText = {fontSize: '1.2rem', lineHeight: '2rem'};

        return (
            <section id="contact" data-animate="bounceIn" className="contact-section contact bg-gray">
                <div className="container">
                    <header className="text-center">
                        <h2 className="title">Contact Us</h2>
                    </header>
                    <div className="row">
                        <div className="col-lg-6">
                            <iframe title="Map to Us" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.9658025482004!2d100.54341491506597!3d13.780935090328608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29c1de80dc161%3A0x74b025f68ef5f51b!2sS.P.+Building!5e0!3m2!1sen!2sth!4v1557225783724!5m2!1sen!2sth" width="100%" height="450" frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <div className="col-lg-6" style={sizeText}>
                            <p><i className="fas fa-map-marker-alt"></i> 388 Phahonyothin Rd., Khwaeng Samsen Nai, Khet Phaya Thai, Krung Thep Maha Nakhon 10400</p>
                            <p><i className="fas fa-phone"></i> <a href="tel:0814827913" alt="Call Us" className="text-muted">081-482-7913</a></p>
                            <p><i className="fas fa-envelope"></i> <a href="mailto:puncorp.app@gmail.com" alt="Mail to Us"  className="text-muted">puncorp.app@gmail.com</a></p>
                        </div>
                    </div>
                    {/* <ContactForm /> */}
                </div>
            </section>
        );
    }
}

export default Contact;