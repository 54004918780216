import React, { Component } from 'react';

class Clients extends Component {
    render() {
        return (
            <section id="customers" className="customers-section">
                <div className="container">
                    <div className="col-md-12">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-16.png" title="Toyota Nont" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-15.png" title="Smart Labour 3" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-14.jpg" title="CU Stroke Fast Track" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-13.jpg" title="CU Stroke" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-12.jpg" title="กรุงเทพธุรกิจ" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-11.jpg" title="Thinking Radio" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-10.jpg" title="AIDS DATA HUB" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-9.jpg" title="TU RADIO" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-8.jpg" title="Smile Cooking Club" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-6.jpg" title="MET107" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-4.jpg" title="MCOT DigitalHall" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="customer"><img src="img/customers/logo-2.jpg" title="IPM" data-placement="bottom" data-toggle="tooltip" alt="" className="img-fluid d-block mx-auto" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Clients;