import React, { Component } from 'react';

class Service extends Component {
    render() {
        const sizeText = {fontSize: '1.1rem', lineHeight: '1.5rem'};

        return (
            <section id="services" className="bg-gradient services-section">
                <div className="container">
                    <header className="text-center">
                        <h2 data-animate="fadeInDown" className="title">Services</h2>
                    </header>
                    <div className="row services text-center">
                        <div data-animate="fadeInUp" className="col-lg-4">
                            <div className="icon"><i className="fab fa-android"></i> + <i className="fab fa-apple"></i></div>
                            <h3 className="heading mb-3 text-400">Android and iOS</h3>
                            <p className="text-left description" style={sizeText}>Software application developed by native Android and iOS, which designed with consideration for the demands and constraints of the devices.</p>
                        </div>
                        <div data-animate="fadeInUp" className="col-lg-4">
                            <div className="icon"><i className="fab fa-react"></i> + <i className="fab fa-laravel"></i></div>
                            <h3 className="heading mb-3 text-400">Web Applications</h3>
                            <p className="text-left description" style={sizeText}>Combination of React and Laravel in order to handle the retrieval and present the information, run on multiple platforms regardless of OS or device as long as the browser is compatible.</p>
                        </div>
                        <div data-animate="fadeInUp" className="col-lg-4">
                            <div className="icon"><i className="fas fa-home"></i> + <i className="fas fa-mobile-alt"></i></div>
                            <h3 className="heading mb-3 text-400">Smart Home</h3>
                            <p className="text-left description" style={sizeText}>Convenient home setup where appliances and devices can be automatically controlled remotely from any internet-connected place using a mobile or other networked device.</p>
                        </div>
                    </div>
                    <hr data-animate="fadeInUp" />
                    <div data-animate="fadeInUp" className="text-center">
                        <p className="lead">Would you like to know more or just discuss something?</p>
                        <p><a href="#contact" className="btn btn-outline-light link-scroll">Contact Us</a></p>
                    </div>
                </div>
            </section>
        );
    }
}

export default Service;